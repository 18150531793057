<template>
  <div class="statistic">
    <v-row style="">
      <v-col cols="3">
        <p style="margin-top:60px;font-size:32px;">
          統計分析
        </p>
      </v-col>
      <v-col cols="9">
        <v-text-field
          style="margin-top:52px;"
          v-model="dateRangeText"
          label="Date range"
          prepend-icon="mdi-calendar"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        depressed
        style="margin:1px 8px 0px 20px;backgroundColor:#C8E2FB;color:rgba(0,0,0,.4)"
        @click="$router.push('/statistic');"
      >
        <v-icon>
          mdi-arrow-left-thin
        </v-icon>
      </v-btn>
      <h2>北區 陳師傅</h2>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="logs"
          class="elevation-1"
          @click:row="dialog = true"
        >
          <template v-slot:item.name="{ item }">
            {{ item.name }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="860"
    >
      <v-card style="padding:20px;">
        <v-row v-for="taskDetail in taskHead" :key="taskDetail.label">
          <v-spacer />
          <v-col cols="1" style="padding:0px">
            <v-icon style="color:#4DBCB8">
              {{taskDetail.icon}}
            </v-icon>
          </v-col>
          <v-col cols="3" style="padding:0px">
            {{ taskDetail.label }}
          </v-col>
          <v-col cols="7" style="padding:0px">
            <p v-if="taskDetail.value === 'member'||taskDetail.value === 'task'">
              {{ $store.state.taskDetail[taskDetail.value][0].name }}
            </p>
            <div v-else-if="taskDetail.value === 'clientInfo'">
              {{ $store.state.taskDetail.clientName }}-{{ $store.state.taskDetail.clientId }}<br>
              {{ $store.state.taskDetail.clientTel }}
            </div>
            <p v-else-if="taskDetail.value === 'start'||taskDetail.value === 'end'">
              {{ new Date($store.state.taskDetail[taskDetail.value]) }}
            </p>
            <p v-else-if="taskDetail.value === 'traffic'">
              0
            </p>
            <p v-else>{{ $store.state.taskDetail[taskDetail.value] }}</p>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <v-row v-if="$store.state.taskDetail.images[0].length > 0">
              <v-col cols="3" v-for="image in $store.state.taskDetail.images" :key="image">
                <!-- <v-img
                  style="width:100%;cursor:pointer;"
                  :src="`https://storage.googleapis.com/shihlei/${$store.state.taskDetail.id}/${image}.jpg`"
                  @click="goPic(`https://storage.googleapis.com/shihlei/${$store.state.taskDetail.id}/${image}.jpg`)"
                ></v-img> -->
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row>
          <v-spacer />
          <v-col cols="11">
            <p>預計總時程
              {{$store.state.taskDetail.totalPlaned}}
              分鐘，總計積分
              {{$store.state.taskDetail.totalScore}}
            </p>
          </v-col>
          <v-spacer />
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Permission',
  components: {

  },
  data: () => ({
    dateRangeText: [],
    newTask: {},
    dialog: false,
    logs: [
      {
        date: '2022/6/1',
        task: '任務A',
        plan: 30,
        actual: 35,
        diff: 5,
        overtime: 0,
        score: 1,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 3,
        imgActual: 3,
      }, {
        date: '2022/6/1',
        task: '任務B',
        plan: 45,
        actual: 45,
        diff: 0,
        overtime: 0,
        score: 2,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 3,
        imgActual: 0,
      }, {
        date: '2022/6/2',
        task: '任務D',
        plan: 90,
        actual: 85,
        diff: -5,
        overtime: 0,
        score: 2,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 4,
        imgActual: 4,
      }, {
        date: '2022/6/3',
        task: '任務C',
        plan: 100,
        actual: 95,
        diff: -5,
        overtime: 0,
        score: 5,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 3,
        imgActual: 2,
      }, {
        date: '2022/6/5',
        task: '任務A',
        plan: 30,
        actual: 35,
        diff: 5,
        overtime: 0,
        score: 1,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 3,
        imgActual: 1,
      }, {
        date: '2022/6/7',
        task: '任務C',
        plan: 100,
        actual: 95,
        diff: -5,
        overtime: 0,
        score: 5,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 4,
        imgActual: 4,
      }, {
        date: '2022/6/8',
        task: '任務G',
        plan: 100,
        actual: 105,
        diff: 5,
        overtime: 0,
        score: 5,
        clientName: '黑晶科技股份有限公司',
        clientTel: '02-29997984',
        address: '新北市三重區重新路五段609巷14號9樓之6',
        imgReuquire: 3,
        imgActual: 4,
      },
    ],
    taskHead: [
      {
        icon: 'mdi-city',
        label: '區域',
        value: 'region',
      }, {
        icon: 'mdi-list-box-outline',
        label: '單據編號',
        value: 'sheetId',
      }, {
        icon: 'mdi-account-box-outline',
        label: '客戶資料',
        value: 'clientInfo',
      }, {
        icon: 'mdi-clipboard-outline',
        label: '任務',
        value: 'task',
      }, {
        icon: 'mdi-account',
        label: '人員',
        value: 'member',
      }, {
        icon: 'mdi-clock',
        label: '開始時間',
        value: 'start',
      }, {
        icon: '',
        label: '結束時間',
        value: 'end',
      }, {
        icon: 'mdi-map-marker',
        label: '地點',
        value: 'address',
      }, {
        icon: 'mdi-car-estate',
        label: '路程積分',
        value: 'traffic',
      },
    ],
    headers: [
      {
        text: '日期', value: 'date', align: 'right', groupable: false,
      }, {
        text: '客戶名稱', value: 'clientName', align: 'left', groupable: false,
      }, {
        text: '電話', value: 'clientTel', align: 'left', groupable: false,
      }, {
        text: '地址', value: 'address', align: 'left', groupable: false,
      }, {
        text: '任務', value: 'task', align: 'right', groupable: false,
      }, {
        text: '預計工時', value: 'plan', align: 'right', groupable: false,
      }, {
        text: '實際工時', value: 'actual', align: 'right', groupable: false,
      }, {
        text: '差異', value: 'diff', align: 'right', groupable: false,
      }, {
        text: '加班時數', value: 'overtime', align: 'right', groupable: false,
      }, {
        text: '獲得積分', value: 'score', align: 'right', groupable: false,
      }, {
        text: '應拍照數', value: 'imgReuquire', align: 'right', groupable: false,
      }, {
        text: '實拍照數', value: 'imgActual', align: 'right', groupable: false,
      },
    ],
    items: [
      {
        id: 1,
        name: '北區',
        children: [
          { id: 2, name: '陳師傅' },
          { id: 3, name: '張師傅' },
          { id: 4, name: '王師傅' },
        ],
      }, {
        id: 5,
        name: '中區',
        children: [
          { id: 6, name: '林師傅' },
          { id: 7, name: '李師傅' },
        ],
      }, {
        id: 8,
        name: '南區',
        children: [
          { id: 9, name: '徐師傅' },
          { id: 10, name: '黃師傅' },
        ],
      }, {
        id: 11,
        name: '淨水',
        children: [
          { id: 12, name: '康師傅' },
        ],
      }, {
        id: 13,
        name: '大水',
        children: [
          { id: 14, name: '黃師傅' },
          { id: 15, name: '許師傅' },
        ],
      },
    ],
  }),
};
</script>
<style scoped>
.test{
  background-color:red;
}
</style>
